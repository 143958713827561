import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Paragraph from "../typography/Paragraph"
import Logo from "../../assets/logo.svg"
import LogoHover from "../../assets/logo-hover.svg"

const StyledModuleWrapper = styled.div`
  position: fixed;
  z-index: 10;
  width: 100%;
`

const StyledContentWrapper = styled.div`
  display: flex;
  margin: 60px 70px;
  margin-bottom: 0;

  @media (max-width: 1440px) {
    margin: 50px;
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 850px) {
    font-size: 14px;
    line-height: 18px;
  }
`

const StyledMenuText = styled.h3`
  font-family: "Lato";
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  position: relative;
  z-index: 2;
  &.margin {
    margin-left: 100px;
    @media (max-width: 767px) {
      margin-left: 20px;
    }
  }
  &.white {
    color: white;
  }

  &:hover {
    &::after {
      content: "${({ name }) => name}";
      position: absolute;
      color: #eee;
      top: -7px;
      left: 0;
      right: 5px;
      margin: 0 auto;
      z-index: -1;
    }
    &::before {
      content: "${({ name }) => name}";
      position: absolute;
      color: ${({ theme }) => theme.colors.green};
      bottom: -7px;
      left: 0;
      right: 5px;
      margin: 0 auto;
      z-index: -1;
    }
  }

  .active {
    &::after {
      content: "${({ name }) => name}";
      position: absolute;
      color: #eee;
      top: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    &::before {
      content: "${({ name }) => name}";
      position: absolute;
      color: ${({ theme }) => theme.colors.green};
      bottom: -7px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
`

const StyledLogo = styled.img`
  width: 34px;
  height: auto;
  &.white {
    filter: invert(100%) sepia(0%) saturate(7482%) hue-rotate(97deg)
      brightness(94%) contrast(105%);
  }
`

const StyedLogoWrapper = styled.div`
  position: relative;
  margin-right: 64px;
  @media (max-width: 767px) {
    margin-right: 30px;
  }

  /* &:hover {
    &::after {
      content: "";
      background-image: url(${LogoHover});
      background-repeat: no-repeat;
      background-color: white;
      background-size: cover;
      width: 429px;
      height: 61px;
      position: absolute;
      top: -10px;
      left: 0;
      bottom: 0;
      /* z-index: 15; */
  /* } */
  /* } */
`

const SocialMedia = ({ data }) => {
  return (
    <StyledModuleWrapper>
      <StyledContentWrapper>
        <p>sociale</p>
      </StyledContentWrapper>
    </StyledModuleWrapper>
  )
}

export default SocialMedia
