import React, { useState } from "react"
import Layout from "../templates/Layout"
import Seo from "../components/seo"
import styled, { keyframes } from "styled-components"
import textBottomBlack from "../assets/text-bottom-black.svg"
import textBottomBlackMobile from "../assets/text-bottom-black-mobile.svg"
import Paragraph from "../components/typography/Paragraph"
import HeadingH2 from "../components/typography/HeadingH2"
import signatureSrc from "../assets/signature.png"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactVivus from "react-vivus"
import Rotate from "react-reveal/Rotate"
import Swing from "react-reveal/Swing"
import Fade from "react-reveal/Fade"
import Menu from "../components/menu/Menu"
import { useSelector, useDispatch } from "react-redux"
import {
  openMenu,
  setActivePatternMenu,
  setActivePage,
  setActiveLanguage,
} from "../store/menuSlice"
import SocialMedia from "../components/socialMedia/SocialMedia"
import { Link } from "gatsby"
import menuMobilePattern from "../assets/home-men-mobile-pattern.svg"

const StyledMenuText = styled.h3`
  font-family: "Lato";
  font-size: 14px;
  line-height: 10px;
  letter-spacing: 5px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  transition: transform 0.3s ease-in-out;
  position: relative;
  z-index: 2;
  color: black;

  &::after {
    content: "${({ name }) => name}";
    position: absolute;
    color: #eee;
    transform: translateY(0px);
    left: 0;
    right: 5px;
    margin: 0 auto;
    z-index: -1;
  }
  &::before {
    content: "${({ name }) => name}";
    position: absolute;
    color: ${({ theme }) => theme.colors.green};
    transform: translateY(0px);
    left: 0;
    right: 5px;
    margin: 0 auto;
    z-index: -1;
  }

  &.margin {
    margin-left: 80px;
    @media (max-width: 1440px) {
      margin-top: 50px;
    }
    @media (max-width: 1024px) {
      margin-left: 20px;
    }
    @media (max-width: 1023px) {
      margin-top: 65px;
      margin-left: 40px;
    }
    @media (max-width: 500px) {
      margin-left: 0px;
    }
  }
  &.margin-rwd {
    margin-left: 0;
    @media (max-width: 1440px) {
      margin-left: 80px;
    }
    @media (max-width: 1024px) {
      margin-left: 20px;
    }
    @media (max-width: 1023px) {
      margin-top: 65px;
      margin-left: 40px;
    }
    @media (max-width: 500px) {
      margin-top: 0px;
      margin-left: 0px;
    }
  }
  &.white {
    color: white;
  }

  &:hover {
    /* transition: transform 0.3s ease-in-out; */
    &::after {
      transform: translateY(-7px);
    }
    &::before {
      transform: translateY(7px);
    }
  }
`

const StyledBackgroundWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #e3e3e3;
  /* background-image: url("${({ bgImage }) => bgImage}"); */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  @media (max-width: 500px) {
    position: fixed;
    width: 100vw;
    height: 100%;
    min-height: -webkit-fill-available;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: auto;
  }
`

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 0 10%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }
  @media (max-width: 500px) {
    height: auto;
    justify-content: flex-start;
    margin-top: 100px;
    padding: 0;
  }
`

const StyledPhotoWrapper = styled.div`
  width: 45%;
  height: auto;
  padding-right: 70px;
  @media (max-width: 1443px) {
    padding-right: 0px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`

const StyledCopyWrapper = styled.div`
  width: 55%;
  height: auto;
  text-align: right;
  padding-right: 150px;
  bottom: 16%;
  position: relative;
  z-index: 2;
  @media (max-width: 1024px) {
    width: 100%;
    padding-right: 0px;
    bottom: 5%;
  }
  @media (max-width: 450px) {
    padding: 0 10%;
    margin-bottom: 120px;
    bottom: 0%;
  }
`

const StyledTextBottom = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: block;
  @media (max-width: 500px) {
    display: none;
  }
`

const StyledTextBottomMobile = styled.img`
  pointer-events: none;
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0px;
  left: 0;
  margin: 0;
  right: 0;
  display: none;
  @media (max-width: 500px) {
    display: block;
  }
`

const rotate = keyframes`
  from {
    transform: rotate(90deg);
opacity:0;
  }

  to {
    transform: rotate(0deg);
opacity:1
  }
  
`

const StyledLine = styled.div`
  width: ${({ isMenuOpen }) => (isMenuOpen ? "50%" : "100%")};
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
  background-color: ${({ isMenuOpen }) => (isMenuOpen ? "black" : "white")};
  transition: width 0.3s ease-in-out, background-color 0.3s ease-in-out;
`

const StyledCrossWrapper = styled.div`
  position: absolute;
  z-index: 9;
  top: 23px;
  left: 80px;
  bottom: 0;
  margin: auto;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  display: block;
  div {
    transform: rotate(${({ isMenuOpen }) => (isMenuOpen ? "45" : "0")}deg);
  }
  @media (max-width: 1030px) {
    display: none;
  }

  @media (max-width: 500px) {
    top: 47px;
    right: 50px;
    left: unset;
    margin: 0;
    &::after {
      content: "";
      position: absolute;
      width: 63px;
      height: 2px;
      background-color: white;
      left: 10px;
      top: 0;
    }
  }
`

const StyledCross = styled.div`
  width: 24px;
  height: 2px;
  position: relative;
  background-color: ${({ theme, isMenuOpen }) =>
    isMenuOpen ? "black" : theme.colors.green};
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: black;
    transform: rotate(90deg);
  }
`

const StyledRightCross = styled.div`
  position: absolute;
  right: 12px;
  width: 24px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.green};
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.green};
    transform: rotate(90deg);
  }
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
`

const StyledLeftCross = styled.div`
  position: absolute;
  left: 12px;
  width: 24px;
  height: 2px;
  background-color: white;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  z-index: 100;
  &::after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background-color: white;
    transform: rotate(90deg);
  }
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
`

const StyledRectangle = styled.div`
  background-image: ${({ pattern, isMenuOpen }) =>
    isMenuOpen ? "url(" + pattern + ")" : ""};

  transition: background-image 0.3s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 483px;
  height: 483px;
  position: absolute;
  border: 2px solid white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  pointer-events: none;
  margin: auto;
  /* animation: ${rotate} 0.9s ease-in-out both; */
  &.visible {
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
`

const StyledPattern = styled.img`
  width: 483px;
  height: 483px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  pointer-events: none;
  margin: auto;
  transform: scale(0.95);

  /* animation: ${rotate} 0.9s ease-in-out both; */
  transition: opacity 0.3s ease-in-out;
  &.visible {
    opacity: 1;
  }
  &.hidden {
    opacity: 0;
  }
  @media (max-width: 1443px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 1024px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 850px) {
    width: 400px;
    height: 400px;
  }
  @media (max-width: 426px) {
    width: 280px;
    height: 280px;
  }
  &.mobile {
    display: none;
    @media (max-width: 1030px) {
      display: block;
    }
  }
`

const StyledGatsbyImage = styled.div`
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;
  z-index: 1;
  transition: filter 0.3s;
  &:hover {
    filter: invert(1);
  }
`

const signature = keyframes`
  from {
opacity:0;
  }

  to {
    opacity:1;
  }
`

const StyledSignature = styled.img`
  width: 200px;
  height: auto;
  z-index: 11;
  transition: transform 0.3s ease-in-out;
  /* animation: ${signature} 0.7s 0.6s ease-in-out both; */
  transform: ${({ isMenuOpen }) => (isMenuOpen ? "scale(0.9)" : "scale(1.0)")};
  @media (max-width: 1443px) {
    width: 116px;
  }
  @media (max-width: 1024px) {
    right: 0px;
    width: 162px;
  }
  @media (max-width: 850px) {
    width: 100px;
  }
  @media (max-width: 426px) {
    width: 90px;
  }
`

const StyledMenuWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 10;
  height: max-content;
  left: 15%;
  transition: opacity 0.3s ease-in-out;
  &.visible {
    opacity: 1;
    display: flex;
  }
  &.hidden {
    opacity: 0;
    display: none;
  }
  @media (max-width: 2200px) {
    left: 11%;
  }
  @media (max-width: 1440px) {
    flex-direction: column;
  }
  @media (max-width: 1023px) {
    top: 0;
    height: auto;
    left: 0;
    right: 0;
    flex-direction: row;
    width: max-content;
  }

  @media (max-width: 500px) {
    top: 0;
    height: auto;
    flex-direction: column;
    width: auto;
    height: max-content;
    left: 16px;
  }
`

const IndexPage = ({ data }) => {
  const isMenuOpen = useSelector(state => state.menu.isMenuOpen)
  const dispatch = useDispatch()

  const [activePhoto, setActivePhoto] = useState("")
  const [activePattern, setActivePattern] = useState("")

  const menuData = data?.datoCmsMenu || ""
  const moduleData = data?.datoCmsHomepage || ""
  const globalData = data?.datoCmsGlobal || ""
  const signature = moduleData?.signature?.url || ""
  const pattern = moduleData?.photo?.url || ""
  const pagesData = menuData?.menuitem || []

  dispatch(setActivePage(""))
  dispatch(setActiveLanguage("en"))
  dispatch(setActivePatternMenu(menuMobilePattern))

  let linkArray = []
  let aboutObject = ""
  let workObject = ""
  let contactObject = ""

  pagesData.map(item => {
    const pageLinkArray = item?.pagelink || []
    return (linkArray = linkArray.concat(pageLinkArray))
  })

  linkArray.map(item => {
    switch (item?.__typename) {
      case "DatoCmsAbout":
        aboutObject = item
        break
      case "DatoCmsWork":
        workObject = item
        break
      case "DatoCmsContact":
        contactObject = item
        break
    }
  })

  const activeMenuPageHandler = page => {
    const photo = page?.menuphoto?.fluid || ""
    const pattern = page?.menupattern?.url || ""
    setActivePhoto(photo)
    setActivePattern(pattern)
  }

  const cleanActiveMenuPageHandler = () => {
    setActivePhoto("")
    setActivePattern("")
  }

  return (
    <Layout>
      <Seo title="Sebastian Hladylowicz" />
      <StyledLine isMenuOpen={isMenuOpen} />
      <StyledCrossWrapper
        isMenuOpen={isMenuOpen}
        onClick={() => dispatch(openMenu())}
      >
        <StyledRightCross className={isMenuOpen ? "visible" : "hidden"} />
        <StyledCross isMenuOpen={isMenuOpen} />
        <StyledLeftCross className={isMenuOpen ? "visible" : "hidden"} />
      </StyledCrossWrapper>
      <StyledMenuWrapper className={isMenuOpen ? "visible" : "hidden"}>
        <Link
          to="/about"
          activeClassName="active"
          state={{ fromHome: true }}
          onClick={() => dispatch(openMenu())}
        >
          <StyledMenuText
            onMouseOver={() => activeMenuPageHandler(aboutObject)}
            onMouseOut={() => cleanActiveMenuPageHandler()}
            className={`margin-rwd `}
            name={aboutObject?.pagename}
          >
            {aboutObject?.pagename}
          </StyledMenuText>
        </Link>

        <Link
          to="/work"
          activeClassName="active"
          state={{ fromHome: true }}
          onClick={() => dispatch(openMenu())}
        >
          <StyledMenuText
            onMouseOver={() => activeMenuPageHandler(workObject)}
            onMouseOut={() => cleanActiveMenuPageHandler()}
            className={`margin `}
            name={workObject?.pagename}
          >
            {workObject?.pagename}
          </StyledMenuText>
        </Link>

        <Link
          to="/contact"
          activeClassName="active"
          state={{ fromHome: true }}
          onClick={() => dispatch(openMenu())}
        >
          <StyledMenuText
            onMouseOver={() => activeMenuPageHandler(contactObject)}
            onMouseOut={() => cleanActiveMenuPageHandler()}
            className={`margin `}
            name={contactObject?.pagename}
          >
            {contactObject?.pagename}
          </StyledMenuText>
        </Link>
      </StyledMenuWrapper>

      <Menu data={menuData} globalData={globalData} isHome={true} />
      <StyledBackgroundWrapper>
        <StyledRectangle isMenuOpen={isMenuOpen}>
          {activePhoto === "" && (
            <StyledSignature isMenuOpen={isMenuOpen} src={signature} />
          )}

          <StyledPattern
            className={isMenuOpen ? "visible" : "hidden"}
            src={activePattern === "" ? pattern : activePattern}
          />

          <StyledPattern className="mobile" src={pattern} />
        </StyledRectangle>

        <StyledContentWrapper>
          <StyledCopyWrapper></StyledCopyWrapper>
          <StyledPhotoWrapper>
            <StyledGatsbyImage>
              <Img fluid={activePhoto} />
            </StyledGatsbyImage>
          </StyledPhotoWrapper>
        </StyledContentWrapper>

        <StyledTextBottom src={textBottomBlack} />

        <StyledTextBottomMobile src={textBottomBlackMobile} />
      </StyledBackgroundWrapper>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query homeQuery {
    datoCmsHomepage {
      photo {
        url
      }
      signature {
        url
      }
    }
    datoCmsGlobal {
      languages
      socialmedia {
        name
        link
      }
      logo {
        url
      }
    }
    datoCmsMenu {
      _allMenuitemLocales {
        locale
      }
      menuitem {
        pagelink {
          __typename
          ... on DatoCmsContact {
            pagename
            menuphoto {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
            menupattern {
              url
            }
          }
          ... on DatoCmsWork {
            pagename
            menuphoto {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
            menupattern {
              url
            }
          }
          ... on DatoCmsHomepage {
            id
          }
          ... on DatoCmsAbout {
            pagename
            menuphoto {
              fluid {
                ...GatsbyDatoCmsFluid
              }
            }
            menupattern {
              url
            }
          }
        }
      }
    }
  }
`
